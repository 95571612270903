import * as React from 'react'
import './style.scss'

interface ContainerProps {
  fullWidth?: boolean
}

const Container: React.FC<ContainerProps> = ({ children }) => {
  return <div className="app-container">{children}</div>
}

export default Container
