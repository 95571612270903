import * as React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import Container from '../../components/Container'
import NavBar from '../../components/NavBar'
import Button from '../../components/Button'
import { SETTINGS, TOAST_SYNC_CONTAINER } from '../../utils/constants'
import './style.scss'
import Storage from '../../utils/storage'
import Footer from '../../components/Footer'
import { SyncMapStore } from '../../contexts/SyncMapStore'
import { getMapSyncObj } from '../../utils/syncObj'
import { createAudienceTag, updateMapSync } from '../../utils/api'
import SyncNotification from '../../components/SyncNotification'
import { IntegrationIcon, TickIcon } from '../../style/icon'
import useAppState from '../../hooks/useAppState'
import { syncSetId } from '../../store/action'
import {
  getAudienceMergeFields,
  getAudienceSegments,
  getAudienceTags
} from '../../utils/api'
import { AudienceStore } from '../../contexts/AudienceStore'
import { ICreateTagMap } from '../../interfaces/ISyncMap'

const SettingLayout: React.FC = ({ children }) => {
  const history = useHistory()
  const { dispatch } = useAppState()
  const { audienceDispatch } = React.useContext(AudienceStore)
  const location = useLocation()
  const [isLoading, setIsLoading] = React.useState(false)
  const {
    audienceId,
    segmentMaps,
    tagMaps,
    fieldMaps,
    syncMapUuid,
    syncMapDispatch
  } = React.useContext(SyncMapStore)
  const setting = SETTINGS.find((item) => item.route === location.pathname) || {
    label: 'N/A',
    confirmLabel: 'Save',
    cancelLabel: 'Cancel'
  }

  const getAudienceDetails = (audienceId: string) => {
    Promise.all([
      getAudienceSegments(audienceId),
      getAudienceTags(audienceId),
      getAudienceMergeFields(audienceId)
    ])
      .then(([segments, tags, mergeFields]) => {
        const activeAudience = {
          id: audienceId,
          segments,
          tags,
          mergeFields
        }
        audienceDispatch({ type: 'select', audience: activeAudience })
        syncMapDispatch({
          type: 'add-audience',
          audienceId: audienceId,
          mergeFields: mergeFields
        })
      })
      .catch((err) => toast.error(err.message))
  }

  const onCancel = () => {
    history.goBack()
  }

  const onSave = async () => {
    setIsLoading(true)
    const synchMapData = { audienceId, segmentMaps, tagMaps, fieldMaps }
    try {
      const payload = getMapSyncObj(synchMapData)
      const res = await updateMapSync(payload, syncMapUuid as string)
      syncMapDispatch({ type: 'add-syncMapUuid', syncMapUuid: res.uuid })
      syncMapDispatch({
        type: 'set-syncMap',
        updatedAt: res.updatedAt,
        audienceId: res.audienceId,
        segmentMaps: res.segmentMap,
        fieldMaps: res.fieldMap
      })
      Storage.set('sync-map', res.uuid)
      dispatch(syncSetId('exists'))
      getAudienceDetails(res.audienceId)
      dispatch(syncSetId('exists'))
      getAudienceDetails(res.audienceId)
      setIsLoading(false)
      toast(
        <SyncNotification
          icon={<IntegrationIcon fill="#fff" />}
          label="Mapping your data. This should only take a moment."
        />,
        { containerId: TOAST_SYNC_CONTAINER, autoClose: 4500 }
      )
      history.replace('/settings')
    } catch (err) {
      setIsLoading(false)
      toast.error('Could not update your Data')
    }
  }

  return (
    <div className="setting-layout-container">
      <NavBar>
        <div className="nav-bar-title">{setting.label}</div>
        <div className="action-button-wrapper">
          <Button
            variant="secondary"
            label={setting.cancelLabel}
            onClick={onCancel}
          />
          <Button
            variant="primary"
            label={setting.confirmLabel}
            onClick={onSave}
          />
        </div>
      </NavBar>
      <Container>
        <div className="content">{children}</div>
      </Container>
      <Footer>
        <div className="footer-wrapper">
          <Button variant="tertiary" label="Cancel" onClick={onCancel} />
          <Button
            variant="primary"
            label="Save"
            disabled={isLoading}
            onClick={onSave}
          />
        </div>
      </Footer>
    </div>
  )
}

export default SettingLayout
