import { v4 as uuidV4 } from 'uuid'
import { IMapField } from '../interfaces/IMapField'

import { ISyncMap } from '../interfaces/ISyncMap'
import { IUser } from '../interfaces/IUser'
import {
  AppAction,
  AUTH_INIT,
  AUTH_MAILCHIMP,
  AUTH_PATREON,
  MAILCHIMP_SET_DATA,
  PATREON_SET_DATA,
  SYNC_ADD_MAP_ITEM,
  SYNC_UPDATE_MAP_ITEM,
  SYNC_REMOVE_MAP_ITEM,
  SYNC_SELECT_AUDIENCE,
  SYNC_SET_DATA
} from './action'

export interface AppStateProps {
  isMailchimpAuthenticated: boolean
  isPatreonAuthenticated: boolean
  mailchimpUser?: IUser
  patreonUser?: IUser
  syncMapId?: string
  mailchimp: {
    segments: any[]
    tags: any[]
    mergeFields: any[]
  }
  patreon: {
    campaigns: any[]
    tiers: any[]
    dataFields: any[]
  }
  sync: ISyncMap
}

export const initialState: AppStateProps = {
  isMailchimpAuthenticated: false,
  isPatreonAuthenticated: false,
  mailchimp: {
    segments: [],
    tags: [],
    mergeFields: []
  },
  patreon: {
    campaigns: [],
    tiers: [],
    dataFields: []
  },
  sync: {}
}

export const reducer = (
  state: AppStateProps,
  action: AppAction
): AppStateProps => {
  let temp = []
  switch (action.type) {
    case AUTH_MAILCHIMP:
      return {
        ...state,
        isMailchimpAuthenticated: true,
        mailchimpUser: action.payload
      }
    case AUTH_PATREON:
      return {
        ...state,
        isPatreonAuthenticated: true,
        patreonUser: action.payload
      }
    case AUTH_INIT:
      return {
        ...state,
        ...initialState
      }
    case MAILCHIMP_SET_DATA:
      return {
        ...state,
        mailchimp: action.payload
      }
    case PATREON_SET_DATA:
      return {
        ...state,
        patreon: action.payload
      }
    case SYNC_SET_DATA:
      return {
        ...state,
        sync: action.payload
      }
    case SYNC_SELECT_AUDIENCE:
      return {
        ...state,
        sync: {
          ...state.sync,
          audienceId: action.payload,
          segments: [{ uuid: uuidV4() }],
          tags: [{ uuid: uuidV4() }],
          fields: [
            { uuid: uuidV4(), mailchimpKey: 'FNAME' },
            { uuid: uuidV4(), mailchimpKey: 'LNAME' },
            { uuid: uuidV4(), mailchimpKey: 'ADDRESS' },
            { uuid: uuidV4(), mailchimpKey: 'PHONE' }
          ]
        }
      }
    case SYNC_ADD_MAP_ITEM:
      temp = [...(state.sync[action.payload.name] || []), action.payload.item]
      return {
        ...state,
        sync: {
          ...state.sync,
          [action.payload.name]: temp
        }
      }
    case SYNC_UPDATE_MAP_ITEM:
      temp = [...(state.sync[action.payload.name] || [])]
      const index = state.sync[action.payload.name]?.findIndex(
        (item: IMapField) => item.uuid === action.payload.uuid
      )
      if (index !== undefined) {
        temp.splice(index, 1, { ...temp[index], ...action.payload.data })
      }
      return {
        ...state,
        sync: {
          ...state.sync,
          [action.payload.name]: temp
        }
      }
    case SYNC_REMOVE_MAP_ITEM:
      temp = [...(state.sync[action.payload.name] || [])].filter(
        (item) => item.uuid !== action.payload.uuid
      )
      return {
        ...state,
        sync: {
          ...state.sync,
          [action.payload.name]: temp
        }
      }
    default: {
      return { ...state }
    }
  }
}
