import * as React from 'react'
import { FSelectOption } from '../Formik/FSelect/FSelect'

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  placeholder?: string
  helper?: string
  options: FSelectOption[]
  $value?: string
  onChange?: (item: any) => void
}

const Select: React.FC<SelectProps> = ({
  label,
  placeholder,
  helper,
  options,
  onChange,
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!onChange) {
      return
    }
    onChange(e.target.value)
  }

  return (
    <div className="wink-form-field-container">
      {label && (
        <label className="wink-form-label" htmlFor="select">
          {label}
        </label>
      )}
      <select
        name="select"
        id="select"
        className="wink-form-select"
        onChange={handleChange}
        {...props}
      >
        <option value="" style={{ display: 'none' }}>
          {placeholder}
        </option>
        {options.map((option, key) => (
          <option key={key} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {helper && <p className="wink-form-description">{helper}</p>}
    </div>
  )
}

export default Select
