import * as React from 'react'

import { CrossIcon } from '../../style/icon'
import './style.scss'

interface SyncNotificationProps {
  icon: React.ReactNode
  label?: string
}

const SyncNotification: React.FC<SyncNotificationProps> = ({ icon, label }) => (
  <div className="notification-container">
    {icon}
    <span>{label}</span>
    <CrossIcon style={{ transform: 'scale(0.67)' }} fill="#fff" />
  </div>
)

export default SyncNotification
