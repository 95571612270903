import * as React from 'react'
import './style.scss'

import MapField from '../../components/MapField'
import Button from '../../components/Button'

import { CampaignStore } from '../../contexts/CampaignStore'
import { AudienceStore } from '../../contexts/AudienceStore'
import {
  ChangeFieldMap,
  RemoveFieldMap,
  SyncMapStore
} from '../../contexts/SyncMapStore'
import { IAudienceMergeField } from '../../interfaces/IAudience'
import { FSelectOption } from '../../components/Formik/FSelect/FSelect'
import { IPatreonDataFields } from '../../interfaces/ICampaign'

const MergeFieldSync: React.FC = () => {
  const {
    activeCampaign: { dataFields }
  } = React.useContext(CampaignStore)

  const {
    activeAudience: { mergeFields: activeMergeFields }
  } = React.useContext(AudienceStore)

  const { fieldMaps, syncMapDispatch } = React.useContext(SyncMapStore)

  const [isLoading] = React.useState<boolean>(false)

  const [sources, setSources] = React.useState<FSelectOption[]>(
    activeMergeFields.map((mgf: IAudienceMergeField) => {
      const option = { label: mgf.name, value: mgf.tag }
      return option
    })
  )
  const [targets] = React.useState<FSelectOption[]>(
    dataFields.map((df: IPatreonDataFields) => {
      const option = { label: df.name, value: df.tag }
      return option
    })
  )
  React.useEffect(() => {
    setSources(
      activeMergeFields.map((mgf: IAudienceMergeField) => {
        const option = { label: mgf.name, value: mgf.tag }
        return option
      })
    )
  }, [activeMergeFields])

  const onAddItem = () => {
    syncMapDispatch({
      type: 'add-field-map',
      fieldMap: { mailchimpKey: undefined, patreonKey: undefined }
    })
  }

  const onChangeItem = (idx: number, type: string, data: any) => {
    const newFieldMap = Object.assign({}, fieldMaps[idx])
    switch (type) {
      case 'mailchimpKey':
        newFieldMap.mailchimpKey = data
        break
      case 'patreonKey':
        newFieldMap.patreonKey = data
        break
      default:
        break
    }
    syncMapDispatch(ChangeFieldMap(idx, newFieldMap))
  }

  const onRemoveItem = (idx = -1) => {
    syncMapDispatch(RemoveFieldMap(idx))
  }

  return (
    <div className="merge-field-sync-container">
      <div className="page-title">Map your merge fields</div>
      <div>Map your Mailchimp Merge Fields to your Patreon Data Fields</div>
      <form>
        {fieldMaps?.map((item, index) => (
          <MapField
            sourceLabel={'Mailchimp Merge Fields'}
            sourceKey={'mailchimpKey'}
            sources={sources}
            targetKey={'patreonKey'}
            targetLabel={'Patreon Data Fields'}
            targets={targets}
            idx={index}
            key={index}
            item={item}
            isRemovable={true}
            isLoading={isLoading}
            onChange={onChangeItem}
            onDelete={onRemoveItem}
          />
        ))}
        <p>
          Patrons&apos; email addresses are automatically mapped to the
          &quot;email&quot; merge field.
        </p>
      </form>
      <div className="controls-container">
        <Button
          variant="primary"
          label="Map Another Merge Field"
          onClick={() => onAddItem()}
        />
      </div>
    </div>
  )
}

export { MergeFieldSync }
