import * as React from 'react'
import Button from '../../components/Button'
import './style.scss'
import { getUserLogs } from '../../utils/api'
import { IUserLog } from '../../interfaces/IUser'
import Storage from '../../utils/storage'

const UserLogs: React.FC = () => {
  const syncMapId = Storage.get('sync-map')
  const [logs, setUserLogs] = React.useState<IUserLog[]>([])
  const [logLimit, setLogRecordsLimit] = React.useState(10)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalCount, setTotalLogsCount] = React.useState(0)

  React.useEffect(() => {
    if (syncMapId) {
      getLogResults(syncMapId, logLimit, currentPage)
    }
  }, [])

  const getLogResults = async (
    syncMapId: string,
    logLimit: number,
    currentPage: number
  ) => {
    const logsResponse = await getUserLogs(
      syncMapId,
      logLimit.toString(),
      currentPage.toString()
    )
    logsResponse.logs.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    setUserLogs(logsResponse.logs)
    setCurrentPage(logsResponse.page)
    setLogRecordsLimit(logsResponse.limit)
    setTotalLogsCount(logsResponse.totalCount)
  }

  const refreshLogs = () => {
    if (syncMapId) {
      getLogResults(syncMapId, logLimit, 1)
    }
  }

  const handlePaging = (direction?: string) => {
    if (!direction) direction = 'next'
    if (syncMapId) {
      let newPage = direction == 'next' ? currentPage + 1 : currentPage - 1
      if (newPage <= 0) newPage = 1
      if (newPage * logLimit - (logLimit - 1) > totalCount) return
      getLogResults(syncMapId, logLimit, newPage)
    }
  }

  const renderLogRows = () => {
    if (logs) {
      const logRows = logs.map((log) => (
        <div className="table-row" key={log.uuid}>
          <div className="table-cell">
            {new Date(log.updatedAt).toLocaleString('en-US', {
              weekday: 'long',
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour12: true
            })}{' '}
            at{' '}
            {new Date(log.createdAt).toLocaleTimeString('en-US', {
              hour12: true,
              hour: '2-digit',
              minute: '2-digit'
            })}
          </div>
          <div className="table-cell">{log.status}</div>
          <div className="table-cell">{log.message}</div>
        </div>
      ))
      return logRows
    } else {
      return (
        <div className="table-rows">
          <div className="table-cell">No records found</div>
        </div>
      )
    }
  }
  return (
    <div className="user-logs-container">
      <div className="sub-page-title">
        <span>
          View the last 7 days of logs for your Mailchimp-Patreon integration
          sync activity.
        </span>
        <Button
          variant="primary"
          onClick={() => {
            refreshLogs()
          }}
          label="Refresh User Logs"
        />
      </div>
      <div className="table user-logs-table">
        <div className="table-header">
          <div className="table-row">
            <div className="table-cell">Date</div>
            <div className="table-cell">Status</div>
            <div className="table-cell">Message</div>
          </div>
        </div>
        <div className="table-body">{renderLogRows()}</div>
      </div>
      <div className="pagination-container">
        <button className="page-prev" onClick={() => handlePaging('prev')}>
          &lt;
        </button>
        <span className="row-count">
          {currentPage == 1 ? 1 : currentPage * logLimit - (logLimit - 1)}-
          {currentPage * logLimit > totalCount
            ? totalCount
            : currentPage * logLimit}{' '}
          of {totalCount}
        </span>
        <button className="page-next" onClick={() => handlePaging('next')}>
          &gt;
        </button>
      </div>
    </div>
  )
}

export { UserLogs }
