import { IMapField } from '../interfaces/IMapField'
import {
  ICreateSyncMap,
  IFieldMap,
  ISegmentMap,
  ISyncMap
} from '../interfaces/ISyncMap'
import { remove } from 'lodash'
export const getMapDataObj = (data: IMapField) => {
  const { uuid, type, ...remainingProps } = data
  return remainingProps
}

export const getMapSyncObj = (data: ICreateSyncMap): ISyncMap => {
  return {
    audienceId: data.audienceId,
    segmentMap: pruneArray(data.segmentMaps as ISegmentMap[]),
    tagMap: [],
    fieldMap: pruneArray(data.fieldMaps as IFieldMap[])
  }
}

const pruneArray = (arr?: Array<Record<string, any>>) => {
  const newArray = Object.assign([], arr)
  if (arr)
    arr.forEach((element, index) => {
      delete element['isNew']
      const keys = Object.keys(element)
      for (const key of keys) {
        if (element[key] === undefined) {
          remove(newArray, element as any)
        }
      }
    })
  return newArray
}
