import * as React from 'react'

import Select from '../Select'
import Input from '../Input'
import { deleteIcon } from '../../style/icon'
import {
  TMapField,
  TSourceKeyIndex,
  TTargeKeyIndex
} from '../../interfaces/ISyncMap'
import { FSelectOption } from '../Formik/FSelect/FSelect'
interface MapFieldProps {
  idx: number
  sources: FSelectOption[]
  sourceKey: TSourceKeyIndex
  sourceLabel: string
  sourcePlaceholder?: string
  targets: FSelectOption[]
  targetKey: TTargeKeyIndex
  targetLabel: string
  targetPlaceholder?: string
  isNew?: boolean
  item: TMapField
  isRemovable?: boolean
  isLoading?: boolean
  onChange?: (id: number, type: string, value: any) => void
  onNewFromItem?: (index: number, value?: string) => void
  onDelete?: (id?: number) => void
}

const MapField: React.FC<MapFieldProps> = ({
  idx,
  sourceKey,
  sources,
  targetKey,
  targets,
  sourcePlaceholder,
  sourceLabel,
  targetLabel,
  item,
  isRemovable,
  isLoading,
  onChange,
  onNewFromItem,
  onDelete
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleChange = (type: string) => (value: any) => {
    if (onChange) {
      onChange(idx, type, value) // Added || 0 to stop typescript error for now
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onNewFromItem && !isLoading) {
      e.preventDefault()
      onNewFromItem(idx, inputRef.current?.value)
    }
  }

  const handleBlur = () => {
    if (onNewFromItem && !isLoading) {
      onNewFromItem(idx, inputRef.current?.value)
    }
  }

  const renderControl = () => {
    if (item.isNew) {
      return (
        <Input
          label={sourceLabel}
          ref={inputRef}
          value={item[sourceKey]}
          placeholder={sourcePlaceholder}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
      )
    }
    return (
      <Select
        label={sourceLabel}
        placeholder="Select..."
        options={sources}
        value={item[sourceKey]}
        onChange={handleChange(sourceKey)}
      />
    )
  }

  return (
    <div className="field-row">
      <span className="field-item order-1">Map</span>
      <div className="field-input-wrapper order-3">{renderControl()}</div>
      <span className="field-item order-4">to</span>
      <div className="field-input-wrapper order-5">
        <Select
          label={targetLabel}
          placeholder="Select..."
          options={targets}
          value={item[targetKey]}
          onChange={handleChange(targetKey)}
        />
      </div>
      {isRemovable && (
        <button
          type="button"
          className="integration-button field-item order-2"
          onClick={() => onDelete && onDelete(idx)}
        >
          <img src={deleteIcon} alt="delete" />
        </button>
      )}
    </div>
  )
}

export default MapField
