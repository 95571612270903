import * as React from 'react'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  variant?: string
  label?: string
}

const Button: React.FC<ButtonProps> = ({
  variant,
  label,
  className,
  ...props
}) => {
  const getClass = (type: any) => {
    const classes = []
    classes.push('wink-button')
    switch (type) {
      case 'primary':
      case 'secondary':
      case 'tertiary':
        classes.push(`wink-button-${type}`)
        break
      default:
        break
    }
    if (className) {
      classes.push(className)
    }
    return classes.join(' ')
  }

  return (
    <button className={getClass(variant)} {...props}>
      {label}
    </button>
  )
}

export default Button
