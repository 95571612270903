import * as React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Storage from './utils/storage'

import AppStateProvider from './contexts/AppStateContext'
import AudienceProvider from './contexts/AudienceStore'
import CampaignProvider from './contexts/CampaignStore'
import SyncNotification from './components/SyncNotification'
import { renderRoutes, routes } from './routes'
import { SOCKET_BASE_URL, TOAST_SYNC_CONTAINER } from './utils/constants'
import { TickIcon } from './style/icon'
import './style/style.scss'
import SyncMapProvider from './contexts/SyncMapStore'
import { io } from 'socket.io-client'

const App: React.FC = () => {
  React.useEffect(() => {
    const token = Storage.getToken()
    const instance = io(SOCKET_BASE_URL, {
      auth: { token },
      withCredentials: false,
      autoConnect: false
    })

    instance.on('connect', () => {
      console.log('connected!')
    })
    instance.on('disconnect', () => {
      console.log('connect error!')
    })
    instance.open()
    instance.on('MemberSyncCompleted', syncComplete)
  }, [])
  function successToast(data: any) {
    return toast(
      <SyncNotification icon={<TickIcon fill="#fff" />} label={data.message} />,
      { containerId: TOAST_SYNC_CONTAINER, autoClose: 3000 }
    )
  }

  function syncComplete(data: any) {
    console.log(data)
    if (data.success) {
      return successToast(data)
    } else {
      toast.error(data.message)
    }
  }

  return (
    <AudienceProvider>
      <CampaignProvider>
        <SyncMapProvider>
          <Router>{renderRoutes(routes)}</Router>
          <ToastContainer enableMultiContainer />
          <ToastContainer
            enableMultiContainer
            containerId={TOAST_SYNC_CONTAINER}
            className="sync-notification-container"
            position="bottom-center"
            autoClose={false}
            closeButton={false}
          />
        </SyncMapProvider>
      </CampaignProvider>
    </AudienceProvider>
  )
}

export default React.memo(App)
