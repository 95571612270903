import { OAUTH2 } from '../utils/constants'

const oauthUrl = () => {
  const getMailchimpOauthUrl = (): string => {
    const { client_id, redirect_uri, authBaseUrl } = OAUTH2.mailchimp
    const query = new URLSearchParams({
      response_type: 'code',
      client_id,
      redirect_uri
    })
    const url = `${authBaseUrl}/authorize?${query.toString()}`
    return url
  }

  const getPatreonOauthUrl = (): string => {
    const { client_id, redirect_uri, authBaseUrl } = OAUTH2.patreon
    const query = new URLSearchParams({
      response_type: 'code',
      redirect_uri,
      client_id,
      scope:
        'identity campaigns campaigns.members campaigns.members.address campaigns.members[email] w:campaigns.webhook'
    })

    const url = `${authBaseUrl}/oauth2/authorize?${query.toString()}`
    return url
  }

  return {
    getPatreonOauthUrl,
    getMailchimpOauthUrl
  }
}

export default oauthUrl()
