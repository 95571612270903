import {
  IGetAudienceResponse,
  IMailchimpAudienceResponse,
  IMailchimpCreateAudience,
  IMailchimpList
} from '../interfaces/IAudience'
import { IGetSyncMapResponse, ISyncMap } from '../interfaces/ISyncMap'
import axiosClient from './axiosClient'
import { IGetUserLogsResponse } from '../interfaces/IUser'

export const authorizeMailchimp = async (code: string): Promise<any> => {
  const res = await axiosClient.post('/oauth/mailchimp-auth', { code })
  const { success } = res.data
  if (success) {
    return res.data
  }
  throw new Error('Mailchimp Oauth failed')
}

export const authorizePatreon = async (code: string): Promise<any> => {
  const res = await axiosClient.post('/oauth/patreon-auth', { code })
  const { success } = res.data
  if (success) {
    return res.data
  }
  throw new Error('Patreon Oauth failed')
}

export const getAudiences = async (): Promise<IMailchimpAudienceResponse> => {
  const {
    data: { success, audiences }
  }: {
    data: IGetAudienceResponse
  } = await axiosClient.get('/mailchimp/audiences')
  if (success) {
    return audiences
  }
  throw new Error('Getting Mailchimp Audiences failed')
}

export const createAudience = async (
  audienceData: IMailchimpCreateAudience
): Promise<IMailchimpList> => {
  const res = await axiosClient.post('/mailchimp/audiences', audienceData)
  const { success, audience } = res.data
  if (success) {
    return audience
  }
  throw new Error(
    'Could not create Audience, Please login to your Mailchimp Account'
  )
}

export const createAudienceSegment = async (
  audienceId: string,
  name: string
): Promise<any> => {
  const res = await axiosClient.post(
    `/mailchimp/audiences/${audienceId}/segments`,
    { name }
  )
  const { success, segment } = res.data
  return success ? segment : null
}

export const getAudienceSegments = async (audienceId: string): Promise<any> => {
  const {
    data: { success, segments }
  }: { data: { success: boolean; segments: any } } = await axiosClient.get(
    `/mailchimp/audiences/${audienceId}/segments`
  )
  if (success) {
    return segments
  }
  throw new Error('Getting Mailchimp Segments failed')
}

export const createAudienceTag = async (
  audienceId: string,
  name: string
): Promise<any> => {
  const res = await axiosClient.post(
    `/mailchimp/audiences/${audienceId}/tags`,
    { name }
  )
  const { success, tag } = res.data
  return success ? tag : null
}

export const getAudienceTags = async (audienceId: string): Promise<any> => {
  const res = await axiosClient.get(`/mailchimp/audiences/${audienceId}/tags`)
  const { success, tags } = res.data
  if (success) {
    return tags
  }
  throw new Error('Getting Mailchimp Tags failed')
}

export const getAudienceMergeFields = async (
  audienceId: string
): Promise<any> => {
  const res = await axiosClient.get(
    `/mailchimp/audiences/${audienceId}/merge-fields`
  )
  const { success, fields } = res.data
  if (success) {
    return fields
  }
  throw new Error('Getting Mailchimp Merge Fields failed')
}

export const getCampaigns = async (): Promise<any[]> => {
  const res = await axiosClient.get('/patreon/campaigns')
  const { success, campaigns } = res.data
  if (success) {
    return campaigns
  }
  throw new Error('Getting Patreon Campaigns failed')
}

export const getTiers = async (): Promise<any> => {
  const res = await axiosClient.get('/patreon/tiers')
  const { success, tiers } = res.data
  if (success) {
    return tiers
  }
  throw new Error('Getting Patreon Tiers failed')
}

export const getDataFields = async (): Promise<any> => {
  const res = await axiosClient.get('/patreon/data-fields')
  const { success, fields } = res.data
  if (success) {
    return fields
  }
  throw new Error('Getting Patreon Data Fields failed')
}

export const createMapSync = async (
  data: ISyncMap
): Promise<IGetSyncMapResponse> => {
  const res = await axiosClient.post('/map-sync', data)
  const { success, syncMap } = res.data
  if (success) {
    return syncMap
  }
  throw new Error('Create Map Sync failed')
}

export const updateMapSync = async (
  data: ISyncMap,
  id: string
): Promise<IGetSyncMapResponse> => {
  const res = await axiosClient.put(`/map-sync/${id}`, data)
  const { success, syncMap } = res.data
  if (success) {
    return syncMap
  }
  throw new Error('Update Map Sync failed')
}

export const getMapSync = async (id: string): Promise<IGetSyncMapResponse> => {
  const res = await axiosClient.get(`/map-sync/${id}`)
  const { success, syncMap } = res.data
  if (success) {
    return syncMap
  }
  throw new Error('Get Map Sync failed')
}

export async function revokeOauth(): Promise<void> {
  return await axiosClient.post(`/oauth/revoke`)
}

export const getUserLogs = async (
  syncMapId?: string,
  limit = '10',
  page = '1'
): Promise<IGetUserLogsResponse> => {
  const query = new URLSearchParams({
    limit: limit,
    page: page
  })
  const res = await axiosClient.get(
    `/map-sync/${syncMapId}/logs?${query.toString()}`
  )
  const results = res.data

  if (results.success) {
    return results
  }
  throw new Error('Get User Logs failed')
}
