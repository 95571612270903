import * as React from 'react'
import { Redirect } from 'react-router-dom'

import useAppState from '../../../hooks/useAppState'

const AuthGuard: React.FC = ({ children }) => {
  const { isMailchimpAuthenticated, isPatreonAuthenticated } = useAppState()

  if (!isMailchimpAuthenticated || !isPatreonAuthenticated) {
    return <Redirect to="/auth" />
  }

  return <>{children}</>
}

export default AuthGuard
