import * as React from 'react'
import {
  IActiveAudience,
  IMailchimpAudienceSegment,
  IMailchimpAudienceTag,
  IMailchimpList
} from '../interfaces/IAudience'
import { uniqWith, isEqual } from 'lodash'
import Storage from '../utils/storage'
import { useEffect } from 'react'

interface IAudienceState {
  audiences: void | IMailchimpList[]
  activeAudience: IActiveAudience
  mayCreate: boolean
}
interface IAudienceContext extends IAudienceState {
  audienceDispatch: React.Dispatch<AudienceAction>
}

export const AudienceStore = React.createContext<IAudienceContext>({
  audiences: [] as IMailchimpList[],
  activeAudience: {} as IActiveAudience,
  mayCreate: false,
  audienceDispatch: () => null
})
const { Provider } = AudienceStore

type AudienceAction =
  | { type: 'add'; audiences: IMailchimpList[] }
  | { type: 'permission'; permit: boolean }
  | { type: 'select'; audience: IActiveAudience }
  | { type: 'add-tag'; tag: IMailchimpAudienceTag }
  | { type: 'add-segment'; segment: IMailchimpAudienceSegment }

const addTag = (
  tag: IMailchimpAudienceTag,
  activeAudience: IActiveAudience
) => {
  const tags = [...activeAudience.tags, tag]
  activeAudience.tags = tags
  return activeAudience
}

const addSegment = (
  segment: IMailchimpAudienceSegment,
  activeAudience: IActiveAudience
) => {
  const segments = [...activeAudience.segments, segment]
  activeAudience.segments = segments
  return activeAudience
}

const audienceReducer = (
  state: IAudienceContext,
  action: AudienceAction
): IAudienceContext => {
  switch (action.type) {
    case 'add':
      const newAudiences = state.audiences
        ? uniqWith([...state.audiences, action.audiences].flat(), isEqual)
        : action.audiences
      return {
        ...state,
        audiences: newAudiences
      }
    case 'permission':
      return { ...state, mayCreate: action.permit }
    case 'select':
      return { ...state, activeAudience: action.audience }
    case 'add-tag':
      return {
        ...state,
        activeAudience: addTag(action.tag, state.activeAudience)
      }
    case 'add-segment':
      return {
        ...state,
        activeAudience: addSegment(action.segment, state.activeAudience)
      }
    default:
      throw new Error()
  }
}
const initialAudienceState = JSON.parse(Storage.get('audience') as string) || {}
const AudienceProvider: React.FC = ({ children }) => {
  const [state, audienceDispatch] = React.useReducer(
    audienceReducer,
    initialAudienceState as IAudienceContext
  )

  useEffect(() => {
    Storage.set('audience', JSON.stringify(state))
  }, [state])

  return <Provider value={{ ...state, audienceDispatch }}>{children}</Provider>
}

export default AudienceProvider
