import * as React from 'react'
import { IAudienceMergeField } from '../interfaces/IAudience'
import {
  ICreateSyncMap,
  IFieldMap,
  ICreateSegmentMap,
  ISegmentMap
} from '../interfaces/ISyncMap'
import { uniqWith, isEqual } from 'lodash'
import Storage from '../utils/storage'

type ISyncMapState = ICreateSyncMap

interface ISyncMapContext extends ISyncMapState {
  syncMapUuid?: string
  updatedAt?: Date
  syncMapDispatch: React.Dispatch<SyncMapAction>
}

export const SyncMapStore = React.createContext<ISyncMapContext>({
  audienceId: '',
  segmentMaps: [] as ICreateSegmentMap[],
  tagMaps: [],
  fieldMaps: [] as IFieldMap[],
  syncMapDispatch: () => null
})
const { Provider } = SyncMapStore

const defaultState = {
  audienceId: '',
  updatedAt: '',
  segmentMaps: [] as ICreateSegmentMap[],
  fieldMaps: [] as IFieldMap[],
  syncMapDispatch: () => null
}
const initialState =
  JSON.parse(Storage.get('syncMapState') as string) || defaultState

type SyncMapAction =
  | {
      type: 'set-syncMap'
      audienceId: string
      updatedAt: Date
      segmentMaps: ISegmentMap[]
      fieldMaps: IFieldMap[]
    }
  | { type: 'add-syncMapUuid'; syncMapUuid: string }
  | {
      type: 'add-audience'
      audienceId: string
      mergeFields?: IAudienceMergeField[]
    }
  | { type: 'add-segment-map'; segmentMap: ICreateSegmentMap }
  | { type: 'change-segment-map'; segmentMap: ICreateSegmentMap; idx: number }
  | { type: 'remove-segment-map'; idx: number }
  | { type: 'add-field-map'; fieldMap: IFieldMap }
  | { type: 'change-field-map'; fieldMap: IFieldMap; idx: number }
  | { type: 'remove-field-map'; idx: number }

const syncMapReducer = (
  state: ISyncMapContext,
  action: SyncMapAction
): ISyncMapContext => {
  const newSegmentMaps = state.segmentMaps
    ? Array.from(state.segmentMaps)
    : ([] as ICreateSegmentMap[])
  const newFieldMaps = state.fieldMaps
    ? Array.from(state.fieldMaps)
    : ([] as IFieldMap[])
  switch (action.type) {
    case 'set-syncMap':
      return {
        ...state,
        audienceId: action.audienceId,
        updatedAt: action.updatedAt,
        segmentMaps: action.segmentMaps,
        fieldMaps: action.fieldMaps
      }
    case 'add-syncMapUuid':
      return {
        ...state,
        syncMapUuid: action.syncMapUuid
      }
    case 'add-audience':
      let defaultFieldMaps
      if (action.mergeFields && state.fieldMaps.length === 0) {
        defaultFieldMaps = defaultMergeFields(action.mergeFields)
        return {
          ...state,
          audienceId: action.audienceId,
          fieldMaps: uniqWith(defaultFieldMaps, isEqual)
        }
      } else {
        return {
          ...state,
          audienceId: action.audienceId
        }
      }
    case 'add-segment-map':
      return {
        ...state,
        segmentMaps: [...state.segmentMaps, action.segmentMap]
      }
    case 'remove-segment-map':
      newSegmentMaps.splice(action.idx, 1)
      return {
        ...state,
        segmentMaps: newSegmentMaps
      }
    case 'change-segment-map':
      newSegmentMaps[action.idx] = action.segmentMap
      return { ...state, segmentMaps: newSegmentMaps }
    case 'add-field-map':
      return {
        ...state,
        fieldMaps: [...state.fieldMaps, action.fieldMap]
      }
    case 'change-field-map':
      newFieldMaps[action.idx] = action.fieldMap
      return {
        ...state,
        fieldMaps: newFieldMaps
      }
    case 'remove-field-map':
      newFieldMaps.splice(action.idx, 1)
      return {
        ...state,
        fieldMaps: newFieldMaps
      }
    default:
      throw new Error()
  }
}

const defaultMergeFields = (fields: IAudienceMergeField[]): IFieldMap[] => {
  const matchingFields = fields.filter((field) => {
    return (
      field.name === 'Full Name' ||
      field.name === 'Phone Number' ||
      field.name === 'Campaign Lifetime Revenue'
    )
  })

  return matchingFields.map((f) => {
    const mf = {
      mailchimpKey: f.tag,
      patreonKey: undefined
    }
    return mf
  })
}

const SyncMapProvider: React.FC = ({ children }) => {
  const [state, syncMapDispatch] = React.useReducer(
    syncMapReducer,
    initialState
  )
  // console.log('SyncMap state', state)

  React.useEffect(() => {
    Storage.set('syncMapState', JSON.stringify(state))
  }, [state])

  return <Provider value={{ ...state, syncMapDispatch }}>{children}</Provider>
}

export const CreateNewSegmentMap = (isNew?: boolean): SyncMapAction => {
  return {
    type: 'add-segment-map',
    segmentMap: { tierId: undefined, segment: undefined, isNew }
  }
}

export const ChangeSegmentMap = (
  idx: number,
  segmentMap: ICreateSegmentMap
): SyncMapAction => {
  return { type: 'change-segment-map', segmentMap, idx }
}

export const ChangeFieldMap = (
  idx: number,
  fieldMap: IFieldMap
): SyncMapAction => {
  return {
    type: 'change-field-map',
    fieldMap,
    idx
  }
}

export const RemoveSegmentMap = (idx: number): SyncMapAction => {
  return { type: 'remove-segment-map', idx }
}

export const RemoveFieldMap = (idx: number): SyncMapAction => {
  return { type: 'remove-field-map', idx }
}

export default SyncMapProvider
