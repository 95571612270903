import * as React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { menuRightIcon } from '../../style/icon'
import './style.scss'

export interface BreadcrumbItemProps {
  label: string
  route: string
  isActive?: boolean
  isDisabled?: boolean
  isLast?: boolean
}

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  label,
  route,
  isActive,
  isLast
}) => {
  return (
    <div
      className={classNames('breadcrumb-item-container', { active: isActive })}
    >
      <Link to={route}>{label}</Link>
      {!isLast && <img src={menuRightIcon} />}
    </div>
  )
}

export default BreadcrumbItem
