import axios from 'axios'
import { API_BASE_URL } from './constants'
import Storage from './storage'
const createAxiosClient = () => {
  const instance = axios.create({
    baseURL: API_BASE_URL
  })
  instance.interceptors.request.use((config) => {
    try {
      const token = Storage.getToken()
      config.headers.Accept = 'application/json,text/plain'
      config.headers['Content-Type'] = 'application/json'
      config.headers['Cache-Control'] = 'no-cache'

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    } catch (err) {
      // return original config if JSON.parse fails
      return config
    }
  })

  instance.interceptors.response.use(undefined, async (error) => {
    const status = error.response ? error.response.status : null
    if (status === 401) {
      await instance.post(`/oauth/revoke`).then((res) => {
        Storage.clear()
        window.location.href = ''
      })
    }
    if (status === 401 || status === 400 || status === 500) {
      return Promise.reject(new Error(error.response.data.message))
    }
    return Promise.reject(error)
  })

  return instance
}

export default createAxiosClient()
