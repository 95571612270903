import * as React from 'react'
import { toast } from 'react-toastify'

import Container from '../../components/Container'
import Button from '../../components/Button'
import { SettingItem } from './SettingItem'
import { syncSetData } from '../../store/action'
import useAuth from '../../hooks/useAuth'
import useAppState from '../../hooks/useAppState'
import { SETTINGS } from '../../utils/constants'
import { revokeOauth, getMapSync } from '../../utils/api'
import './style.scss'
import { SyncMapStore } from '../../contexts/SyncMapStore'
import { AudienceStore } from '../../contexts/AudienceStore'
import { CampaignStore } from '../../contexts/CampaignStore'
import {
  getAudienceMergeFields,
  getAudiences,
  getAudienceSegments,
  getAudienceTags,
  getCampaigns,
  getDataFields,
  getTiers
} from '../../utils/api'
import { IMailchimpAudienceResponse } from '../../interfaces/IAudience'
import Storage from '../../utils/storage'

const Settings: React.FC = () => {
  const { dispatch } = useAppState()
  const { logout } = useAuth()
  const [error, setError] = React.useState<boolean | string>(false)
  const { syncMapUuid, syncMapDispatch } = React.useContext(SyncMapStore)
  const { audienceDispatch } = React.useContext(AudienceStore)
  const { campaignDispatch } = React.useContext(CampaignStore)
  const [lastSyncString, setLastSyncString] = React.useState<
    string | undefined
  >()

  const getAudienceDetails = (audienceId: string) => {
    Promise.all([
      getAudienceSegments(audienceId),
      getAudienceTags(audienceId),
      getAudienceMergeFields(audienceId)
    ])
      .then(([segments, tags, mergeFields]) => {
        const activeAudience = {
          id: audienceId,
          segments,
          tags,
          mergeFields
        }
        audienceDispatch({ type: 'select', audience: activeAudience })
        syncMapDispatch({
          type: 'add-audience',
          audienceId: audienceId,
          mergeFields: mergeFields
        })
      })
      .catch((err) => toast.error(err.message))
  }

  const getCampaignDetails = async () => {
    Promise.all([getCampaigns(), getTiers(), getDataFields()])
      .then(([campaigns, tiers, dataFields]) => {
        campaignDispatch({
          type: 'add',
          campaign: {
            id: campaigns[0].id,
            name: campaigns[0].name,
            tiers,
            dataFields
          }
        })
      })
      .catch((err) => toast.error(err.message))
  }

  const getSyncData = async () => {
    if (!syncMapUuid) {
      return
    }
    try {
      if (syncMapUuid) {
        const res = await getMapSync(syncMapUuid)
        Storage.set('sync-map', res.uuid)
        dispatch(syncSetData(res))
        setLastSyncString(
          `Time of last sync: ${new Date(res.updatedAt).toLocaleString(
            'en-US',
            {
              weekday: 'long',
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour12: true
            }
          )} at ${new Date(res.updatedAt).toLocaleTimeString('en-US', {
            hour12: true,
            timeZoneName: 'short'
          })}`
        )

        syncMapDispatch({ type: 'add-syncMapUuid', syncMapUuid: res.uuid })
        syncMapDispatch({
          type: 'set-syncMap',
          updatedAt: res.updatedAt,
          audienceId: res.audienceId,
          segmentMaps: res.segmentMap,
          fieldMaps: res.fieldMap
        })
        getAudienceDetails(res.audienceId)
        getCampaignDetails()
      }
    } catch (err) {
      toast.error('Could not retrieve your existing Data')
    }
  }

  React.useEffect(() => {
    getSyncData()
    getAudiences()
      .then((audienceResponse: IMailchimpAudienceResponse) => {
        const { lists } = audienceResponse
        if (lists.length === 0) {
          audienceDispatch({ type: 'permission', permit: true })
        } else {
          audienceDispatch({ type: 'add', audiences: lists })
          audienceDispatch({
            type: 'permission',
            permit: audienceResponse.constraints.may_create
          })
        }
      })
      .catch(async (err) => {
        try {
          logout()
        } catch (e) {
          toast.error(err.message)
        }
        toast.error('Please log back in to Reauthorize')
      })
  }, [])

  React.useEffect(() => {
    getSyncData()
  }, [syncMapUuid])

  const revoke = async () => {
    setError(false)
    try {
      await revokeOauth()
      logout()
    } catch (e) {
      setError(e.message)
    }
  }

  return (
    <Container>
      <div className="settings-container">
        <div className="page-title">Manage your integration</div>
        <div>
          Update your integration settings, disconnect the integration, or
          reinstall
        </div>
        <div className="settings">
          {SETTINGS.map((item, index) => (
            <SettingItem key={index} {...item} />
          ))}
        </div>
        <div className="controls-container">
          <div className="field-row">
            <Button
              variant="primary"
              label="Disconnect Integration"
              onClick={revoke}
            />
            {lastSyncString ? <p>{lastSyncString}</p> : null}
          </div>

          {error ? (
            <p className="wink-form-description-error">{error}</p>
          ) : null}
        </div>
        <button
          className="integration-button integration-button__logout"
          onClick={logout}
        >
          Log out
        </button>
      </div>
    </Container>
  )
}

export { Settings }
