import * as React from 'react'

import BreadcrumbItem, { BreadcrumbItemProps } from './BreadcrumbItem'
import './style.scss'

interface BreadcrumbProps {
  items: BreadcrumbItemProps[]
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => (
  <div className="breadcrumb-container">
    {items.map((item, index) => (
      <BreadcrumbItem
        key={index}
        isLast={index === items.length - 1}
        {...item}
      />
    ))}
  </div>
)

export default Breadcrumb
