const STORAGE = localStorage
const TOKEN = 'token'

export default class Storage {
  static setToken(token: string): void {
    STORAGE.setItem(TOKEN, token)
  }
  static removeToken(): void {
    STORAGE.removeItem(TOKEN)
  }

  static getToken(): string {
    return STORAGE.getItem(TOKEN) || '{}'
  }

  static clear(): void {
    STORAGE.clear()
  }

  static set(name: string, value: string): void {
    STORAGE.setItem(name, value)
  }

  static remove(name: string): void {
    STORAGE.removeItem(name)
  }

  static get(name: string): string | null {
    return STORAGE.getItem(name)
  }
}
