import * as React from 'react'
import { Field } from 'formik'

interface FInputProps {
  name: string
  type?: string
  label?: string
  helper?: string
  marker?: string
  placeholder?: string
}

const FInput: React.FC<FInputProps> = ({
  name,
  label,
  helper,
  marker,
  placeholder,
  type = 'text'
}) => (
  <Field name={name}>
    {({ field }: { field: typeof Field }) => (
      <div className="wink-form-field-container">
        {label && (
          <div className="wink-form-label-container">
            <label className="wink-form-label" htmlFor="field-input">
              {label}
            </label>
            {marker && (
              <span
                aria-describedby="field-input"
                className="wink-form-label-secondary"
              >
                {marker}
              </span>
            )}
          </div>
        )}
        <input
          className="wink-form-field"
          placeholder={placeholder}
          type={type}
          {...field}
        />
        {helper && (
          <p aria-describedby="field-input" className="wink-form-description">
            {helper}
          </p>
        )}
      </div>
    )}
  </Field>
)

export default FInput
