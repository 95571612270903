import * as React from 'react'
import { RouterProps } from 'react-router'
import { Switch, Redirect, Route } from 'react-router-dom'

import MainLayout from './layouts/MainLayout'
import SettingLayout from './layouts/SettingLayout'
import AuthGuard from './components/Guards/AuthGuard'
import GuestGuard from './components/Guards/GuestGuard'

import {
  Authentication,
  MailchimpAuth,
  PatreonAuth
} from './pages/Authentication'
import { AudienceSync } from './pages/AudienceSync'
import { MergeFieldSync } from './pages/MergeFieldSync'
import { Settings } from './pages/Settings'
import { UserLogs } from './pages/UserLogs'

interface IRoute {
  exact?: boolean
  layout?: React.FC
  guard?: React.FC
  path?: string
  component: React.FC<RouterProps>
  routes?: IRoute[]
}

export const renderRoutes = (routes: IRoute[] = []): React.ReactNode => (
  <React.Suspense fallback={<>Loading</>}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || React.Fragment
        const Layout = route.layout || React.Fragment
        const Component = route.component

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        )
      })}
    </Switch>
  </React.Suspense>
)

export const routes: IRoute[] = [
  {
    exact: true,
    layout: MainLayout,
    guard: GuestGuard,
    path: '/auth',
    component: Authentication
  },
  {
    exact: true,
    layout: MainLayout,
    guard: GuestGuard,
    path: '/auth-mailchimp',
    component: MailchimpAuth
  },
  {
    exact: true,
    layout: MainLayout,
    guard: GuestGuard,
    path: '/auth-patreon',
    component: PatreonAuth
  },
  {
    exact: true,
    layout: MainLayout,
    guard: AuthGuard,
    path: '/audience',
    component: AudienceSync
  },
  {
    exact: true,
    layout: MainLayout,
    guard: AuthGuard,
    path: '/merge-field',
    component: MergeFieldSync
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/settings',
    component: Settings
  },
  {
    exact: true,
    layout: SettingLayout,
    guard: AuthGuard,
    path: '/audience-setting',
    component: AudienceSync
  },
  {
    exact: true,
    layout: SettingLayout,
    guard: AuthGuard,
    path: '/user-logs',
    component: UserLogs
  },
  {
    exact: true,
    layout: SettingLayout,
    guard: AuthGuard,
    path: '/merge-field-setting',
    component: MergeFieldSync
  },
  {
    component: () => <Redirect to="/auth" />
  }
]
