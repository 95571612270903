import * as React from 'react'

import useAppState from '../../hooks/useAppState'
import Button from '../../components/Button'
import oauthUrl from '../../utils/oauthUrl'

import { arrowLeftRightIcon, tickCircleFillIcon } from '../../style/icon'
import './style.scss'

interface AuthenticatedUserProps {
  name: string
}

const AuthenticatedUser: React.FC<AuthenticatedUserProps> = ({ name }) => (
  <div className="auth-user-wrapper">
    <img src={tickCircleFillIcon} alt="check" />
    <div>
      Successfully logged in as
      <br />[{name}]
    </div>
  </div>
)

const Authentication: React.FC = () => {
  const {
    isMailchimpAuthenticated,
    isPatreonAuthenticated,
    mailchimpUser,
    patreonUser
  } = useAppState()

  const onOpenWindow = (url: string) => (): void => {
    window.open(url, '_self')
  }

  return (
    <div className="auth-page-container">
      <div className="page-title">Login to your accounts</div>
      <div>
        Login to your Mailchimp and Patreon accounts to grant the <br />{' '}
        necessary permissions in order for the integration to work properly
      </div>
      <div className="logo-container">
        <div className="card">
          <div className="logo logo-mailchimp" />
          {isMailchimpAuthenticated ? (
            <AuthenticatedUser name={mailchimpUser?.accountName || 'N/A'} />
          ) : (
            <Button
              className="login-button"
              variant="primary"
              label="Log in"
              onClick={onOpenWindow(oauthUrl.getMailchimpOauthUrl())}
            />
          )}
        </div>
        <img className="arrow-left-right" src={arrowLeftRightIcon} />
        <div className="card">
          <div className="logo logo-patreon"></div>
          {isPatreonAuthenticated ? (
            <AuthenticatedUser name={patreonUser?.accountName || 'N/A'} />
          ) : (
            <Button
              className="login-button"
              variant="primary"
              label="Log in"
              disabled={!isMailchimpAuthenticated}
              onClick={onOpenWindow(oauthUrl.getPatreonOauthUrl())}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export { Authentication }
