import { useContext } from 'react'
import {
  AppStateContext,
  AppStateContextProps
} from '../contexts/AppStateContext'

const useAppState = (): AppStateContextProps => {
  const context = useContext(AppStateContext)
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider')
  }
  return context
}

export default useAppState
