import useAppState from './useAppState'
import { authInit, authMailchimp, authPatreon } from '../store/action'
import { IUser } from '../interfaces/IUser'
import Storage from '../utils/storage'
import socket from '../utils/socket'

const useAuth = () => {
  const { dispatch } = useAppState()

  const setAuthMailchimp = (token: string, user: IUser): void => {
    Storage.setToken(token)
    Storage.set('mailchimp', JSON.stringify(user))
    dispatch(authMailchimp(user))
  }

  const setAuthPatreon = (user: IUser): void => {
    Storage.set('patreon', JSON.stringify(user))
    dispatch(authPatreon(user))
  }

  const logout = (): void => {
    Storage.clear()
    socket.disconnect()
    dispatch(authInit())
  }

  return {
    setAuthMailchimp,
    setAuthPatreon,
    logout
  }
}

export default useAuth
