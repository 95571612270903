import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import SyncNotification from '../components/SyncNotification'
import useAppState from './useAppState'
import { STEPS, TOAST_SYNC_CONTAINER } from '../utils/constants'
import { createMapSync } from '../utils/api'
import { getMapSyncObj } from '../utils/syncObj'
import { AudienceStore } from '../contexts/AudienceStore'
import { syncSetId } from '../store/action'
import { IntegrationIcon, TickIcon } from '../style/icon'
import Storage from '../utils/storage'
import { SyncMapStore } from '../contexts/SyncMapStore'

const useNavigation = (): any => {
  const {
    dispatch,
    isMailchimpAuthenticated,
    isPatreonAuthenticated
  } = useAppState()
  const history = useHistory()
  const { activeAudience } = React.useContext(AudienceStore)
  const {
    audienceId,
    segmentMaps,
    tagMaps,
    fieldMaps,
    syncMapDispatch
  } = React.useContext(SyncMapStore)

  const synchMapData = { audienceId, segmentMaps, tagMaps, fieldMaps }
  const handleNext = async (route: string): Promise<void> => {
    const mergePath = /^\/merge-field$/

    if (mergePath.test(route)) {
      try {
        toast(
          <SyncNotification
            icon={<IntegrationIcon fill="#fff" />}
            label="Mapping your data. This should only take a moment."
          />,
          { containerId: TOAST_SYNC_CONTAINER, autoClose: 4500 }
        )
        const payload = getMapSyncObj(synchMapData)
        const res = await createMapSync(payload)
        Storage.set('sync-map', 'res.uuid')
        dispatch(syncSetId('exists'))
        syncMapDispatch({ type: 'add-syncMapUuid', syncMapUuid: res.uuid })
        syncMapDispatch({
          type: 'set-syncMap',
          updatedAt: res.updatedAt,
          audienceId: res.audienceId,
          segmentMaps: res.segmentMap,
          fieldMaps: res.fieldMap
        })
      } catch (err) {
        toast.error(err.message)
      }
    }
    history.push(STEPS[route].next)
  }

  const isNextAvailable = (route: string): boolean => {
    if (!isMailchimpAuthenticated || !isPatreonAuthenticated) {
      return false
    }
    if (route === '/auth') {
      return true
    }
    if (!activeAudience?.id || !audienceId) {
      return false
    }
    return true
  }

  return {
    handleNext,
    isNextAvailable
  }
}

export default useNavigation
