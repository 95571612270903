import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AudienceStore } from '../../contexts/AudienceStore'
import { SyncMapStore } from '../../contexts/SyncMapStore'
import Storage from '../../utils/storage'
import useAuth from '../../hooks/useAuth'
import useQuery from '../../hooks/useQuery'
import { authorizePatreon, getMapSync } from '../../utils/api'
import './style.scss'

const PatreonAuth: React.FC = () => {
  const history = useHistory()
  const { setAuthPatreon } = useAuth()
  const { getParam } = useQuery()
  const { syncMapDispatch } = React.useContext(SyncMapStore)

  const code = getParam('code')

  React.useEffect(() => {
    let hasMap = false
    if (!code) {
      return
    }
    authorizePatreon(code)
      .then(async (res) => {
        setAuthPatreon(res.patreonUser)
        toast.success('Patreon authentication success.')
        if (res.syncMapIds.length > 0) {
          const syncMapId = res.syncMapIds[0]
          try {
            hasMap = true
            const mapSyncResponse = await getMapSync(syncMapId)
            syncMapDispatch({
              type: 'add-syncMapUuid',
              syncMapUuid: mapSyncResponse.uuid
            })
            syncMapDispatch({
              type: 'set-syncMap',
              updatedAt: res.updatedAt,
              audienceId: mapSyncResponse.audienceId,
              segmentMaps: mapSyncResponse.segmentMap,
              fieldMaps: mapSyncResponse.fieldMap
            })
            if (mapSyncResponse?.uuid) {
              Storage.set('sync-map', mapSyncResponse.uuid)
            }
          } catch (e) {}
        }
      })
      .catch((err) => {
        toast.error(err.message)
      })
      .finally(() => {
        if (hasMap) {
          history.replace('/settings')
        } else {
          history.replace('/auth')
        }
      })
  }, [code])

  return <div>Authenticating with Patreon...</div>
}

export { PatreonAuth }
