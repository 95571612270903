import { io } from 'socket.io-client'
import Storage from './storage'
import {
  SOCKET_BASE_URL,
  SOCKET_EVENT_CONNECT,
  SOCKET_EVENT_ERROR
} from './constants'

const socket = () => {
  let instance: any = null

  const connect = (token: string) =>
    new Promise((resolve, reject) => {
      instance = io(SOCKET_BASE_URL, {
        auth: { token },
        withCredentials: false
      })

      instance.on(SOCKET_EVENT_CONNECT, () => {
        console.log('connected!')
        resolve('Socket is connected!')
      })

      instance.on(SOCKET_EVENT_ERROR, () => {
        console.log('connect error!')
        reject('Socket is not connected!')
      })
    })

  const addListener = (event: string, callback: any): void => {
    if (!instance) {
      return
    }
    instance.on(event, callback)
  }

  const disconnect = () => {
    if (!instance) {
      return
    }
    instance.disconnect()
  }

  return {
    connect,
    addListener,
    disconnect
  }
}

export default socket()
