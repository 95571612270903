import * as React from 'react'
import Modal from 'react-modal'
import countries from 'i18n-iso-countries'
import { Formik, Form, getIn } from 'formik'
import * as Yup from 'yup'
import FInput from '../Formik/FInput'
import Container from '../Container'
import FCheckbox from '../Formik/FCheckbox'
import FTextarea from '../Formik/FTextarea'
import Button from '../Button'
import './style.scss'
import FSelect from '../Formik/FSelect'
import {
  IMailchimpCreateAudience,
  IMailchimpList
} from '../../interfaces/IAudience'
import { createAudience } from '../../utils/api'
import { AudienceStore } from '../../contexts/AudienceStore'
import { SyncMapStore } from '../../contexts/SyncMapStore'

interface NewAudienceModalProps {
  open: boolean
  onClose: () => void
}

const initialValues: IMailchimpCreateAudience = {
  name: '',
  campaign_defaults: {
    from_name: '',
    from_email: '',
    subject: '',
    language: 'english'
  },
  contact: {
    company: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
    phone: ''
  },
  email_type_option: false,
  permission_reminder: '',
  double_optin: false,
  marketing_permissions: false
}

const createAudienceSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('A value is required'),
  contact: Yup.object().shape({
    company: Yup.string().required('A value is required'),
    address1: Yup.string().required('A value is required'),
    address2: Yup.string().optional(),
    city: Yup.string().required('A value is required'),
    state: Yup.string().required('A value is required'),
    zip: Yup.string().required('A value is required'),
    country: Yup.string().required('A value is required'),
    phone: Yup.string().optional().max(15, 'Too Long!')
  }),
  campaign_defaults: Yup.object().shape({
    from_name: Yup.string()
      .max(96, 'Too Long!')
      .required('A value is required'),
    from_email: Yup.string()
      .email('Invalid email')
      .max(96, 'Too Long!')
      .required('A value is required'),
    subject: Yup.string().max(150, 'Too Long!').required('A value is required'),
    language: Yup.string().required('A value is required')
  }),
  permission_reminder: Yup.string().required('A value is required'),
  double_optin: Yup.boolean().required('A value is required'),
  marketing_permissions: Yup.boolean().required('A value is required'),
  email_type_option: Yup.boolean().required('A value is required')
})

const NewAudienceModal: React.FC<NewAudienceModalProps> = ({
  open,
  onClose
}) => {
  const [countryOptions, setCountryOptions] = React.useState<
    { label: string; value: string }[]
  >([{ label: 'Country', value: '' }])

  const [submissionError, setSubmissionError] = React.useState<
    boolean | string
  >(false)

  const { audienceDispatch } = React.useContext(AudienceStore)
  const { syncMapDispatch } = React.useContext(SyncMapStore)
  // Sets country Select options
  React.useEffect(() => {
    countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
    setCountryOptions(
      Object.entries(
        countries.getNames('en', { select: 'official' })
      ).map(([k, v]) => ({ label: v, value: k }))
    )
  }, [])

  return (
    <Modal
      isOpen={open}
      contentLabel="Create Audience"
      onRequestClose={onClose}
      className="create-audience-modal"
    >
      <Container>
        <div className="content-container">
          <h1>Create Audience</h1>
          <h3>Audience details</h3>
          <Formik
            initialValues={initialValues}
            validationSchema={createAudienceSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmissionError(false)
              await createAudience(values)
                .then((res: IMailchimpList) => {
                  setSubmitting(false)
                  audienceDispatch({ type: 'add', audiences: [res] })
                  syncMapDispatch({ type: 'add-audience', audienceId: res.id })
                  onClose()
                })
                .catch((err) => {
                  setSubmissionError(err.message)
                  setSubmitting(false)
                })
            }}
          >
            {({
              errors,
              touched,
              isSubmitting,
              isValid
            }: {
              errors: any
              touched: any
              isSubmitting: boolean
              isValid: boolean
            }) => (
              <Form>
                <FInput label="Audience Name" name="name" />
                {errors.name && touched.name ? (
                  <p className={'invalid-error'}>{errors.name}</p>
                ) : null}

                <FInput
                  label="Default from Name"
                  name="campaign_defaults.from_name"
                />
                {getIn(errors, 'campaign_defaults.from_name') &&
                getIn(touched, 'campaign_defaults.from_name') ? (
                  <p className={'invalid-error'}>
                    {getIn(errors, 'campaign_defaults.from_name')}
                  </p>
                ) : null}

                <FInput
                  label="Default from Email Address"
                  name="campaign_defaults.from_email"
                />
                {getIn(errors, 'campaign_defaults.from_email') &&
                getIn(touched, 'campaign_defaults.from_email') ? (
                  <p className={'invalid-error'}>
                    {getIn(errors, 'campaign_defaults.from_email')}
                  </p>
                ) : null}

                <FInput
                  label="Default Email Subject"
                  name="campaign_defaults.subject"
                />
                {getIn(errors, 'campaign_defaults.subject') &&
                getIn(touched, 'campaign_defaults.subject') ? (
                  <p className={'invalid-error'}>
                    {getIn(errors, 'campaign_defaults.subject')}
                  </p>
                ) : null}

                <FInput type="hidden" name="campaign_defaults.language" />

                <FTextarea
                  name="permission_reminder"
                  label="Permission Reminder"
                  placeholder="Write a short reminder about how the recipient joined your audience"
                />
                {errors.permission_reminder && touched.permission_reminder ? (
                  <p className={'invalid-error'}>
                    {errors.permission_reminder}
                  </p>
                ) : null}

                <FInput name="contact.company" label="Company / Organization" />
                {getIn(errors, 'contact.company') &&
                getIn(touched, 'contact.company') ? (
                  <p className={'invalid-error'}>
                    {getIn(errors, 'contact.company')}
                  </p>
                ) : null}

                <FInput name="contact.address1" label="Address" />
                {getIn(errors, 'contact.address1') &&
                getIn(touched, 'contact.address1') ? (
                  <p className={'invalid-error'}>
                    {getIn(errors, 'contact.address1')}
                  </p>
                ) : null}

                <FInput
                  name="contact.address2"
                  label="Address 2"
                  marker="Optional"
                />

                <FInput name="contact.city" label="City" />
                {getIn(errors, 'contact.city') &&
                getIn(touched, 'contact.city') ? (
                  <p className={'invalid-error'}>
                    {getIn(errors, 'contact.city')}
                  </p>
                ) : null}

                <FInput name="contact.zip" label="Zip / Postal code" />
                {getIn(errors, 'contact.zip') &&
                getIn(touched, 'contact.zip') ? (
                  <p className={'invalid-error'}>
                    {getIn(errors, 'contact.zip')}
                  </p>
                ) : null}

                <FSelect
                  label="Country"
                  name="contact.country"
                  options={countryOptions}
                />

                <FInput
                  name="contact.state"
                  label="State / Province / Region"
                />
                {getIn(errors, 'contact.state') &&
                getIn(touched, 'contact.state') ? (
                  <p className={'invalid-error'}>
                    {getIn(errors, 'contact.state')}
                  </p>
                ) : null}

                <FInput name="contact.phone" label="Phone" marker="Optional" />
                {getIn(errors, 'contact.phone') &&
                getIn(touched, 'contact.phone') ? (
                  <p className={'invalid-error'}>
                    {getIn(errors, 'contact.phone')}
                  </p>
                ) : null}

                <h3>Form Settings</h3>

                <FCheckbox
                  name="double_optin"
                  label="Enable double opt-in"
                  helper="Send contacts an opt-in confirmation email when they subscribe to your audience."
                />

                <FCheckbox
                  name="email_type_option"
                  label="Let users pick plain text or HTML emails"
                  helper="When people sign up for your audience, you can let them specify which email format they prefer to receive.  If they choose 'Plain-text', then they won't receive your fancy HTML version"
                />

                <FCheckbox
                  name="marketing_permissions"
                  label="Enable GDPR fields"
                  helper="Customize your forms to include GDPR fields."
                />

                <div className="controls-container">
                  <Button variant="tertiary" label="Cancel" onClick={onClose} />
                  <Button
                    variant="primary"
                    label="Save"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  />
                  {submissionError && (
                    <p className={'invalid-error'}>{submissionError}</p>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </Modal>
  )
}

export default NewAudienceModal
