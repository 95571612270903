// When running the API Locally this can be the API's ngrok tunnel address or the localhost: + process.env.PORT
export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'localhost:8000'

export const OAUTH2 = {
  mailchimp: {
    client_id: process.env.REACT_APP_MAILCHIMP_CLIENT_ID || '',
    redirect_uri: process.env.REACT_APP_MAILCHIMP_REDIRECT_URL || '',
    authBaseUrl: `https://login.mailchimp.com/oauth2`
  },
  patreon: {
    client_id: process.env.REACT_APP_PATREON_CLIENT_ID || '',
    redirect_uri: process.env.REACT_APP_PATREON_REDIRECT_URL || '',
    authBaseUrl: `https://patreon.com`
  }
}

export const SOCKET_BASE_URL =
  process.env.REACT_APP_SOCKET_URL || 'localhost:8000'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const STEPS: Record<string, any> = {
  '/auth': { label: 'Permissions', route: '/auth', next: '/audience' },
  '/audience': { label: 'Audiences', route: '/audience', next: '/merge-field' },
  '/merge-field': {
    label: 'Merge Fields',
    route: '/merge-field',
    next: '/settings'
  },
  '/finish': { label: 'Finish', route: '/finish' }
}

export const SETTINGS = [
  {
    label: 'Audience Settings',
    confirmLabel: 'Save',
    cancelLabel: 'Cancel',
    route: '/audience-setting'
  },
  {
    label: 'Merge Field Settings',
    confirmLabel: 'Save',
    cancelLabel: 'Cancel',
    route: '/merge-field-setting'
  },
  {
    label: 'User Logs',
    cancelLabel: 'Back to Manage Your Integration',
    route: '/user-logs'
  }
]

export const TOAST_SYNC_CONTAINER = 'sync-toast'

export const SOCKET_EVENT_CONNECT = 'connect'
export const SOCKET_EVENT_ERROR = 'error'
export const SOCKET_EVENT_MEMBER_SYNC_COMPLETED = 'MemberSyncCompleted'
