import * as React from 'react'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  helper?: string
  marker?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, helper, marker, ...props }, ref) => (
    <div className="wink-form-field-container">
      {label && (
        <div className="wink-form-label-container">
          <label className="wink-form-label" htmlFor="field-input">
            {label}
          </label>
          {marker && (
            <span
              aria-describedby="field-input"
              className="wink-form-label-secondary"
            >
              {marker}
            </span>
          )}
        </div>
      )}
      <input ref={ref} className="wink-form-field" type="text" {...props} />
      {helper && (
        <p aria-describedby="field-input" className="wink-form-description">
          {helper}
        </p>
      )}
    </div>
  )
)

export default Input
