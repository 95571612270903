import * as React from 'react'
import { AppAction } from '../store/action'
import { reducer, AppStateProps, initialState } from '../store/reducer'
import Storage from '../utils/storage'

export interface AppStateContextProps extends AppStateProps {
  dispatch: React.Dispatch<AppAction>
}

export const AppStateContext = React.createContext<AppStateContextProps>({
  ...initialState,
  dispatch: () => null
})

const AppStateProvider: React.FC = ({ children }) => {
  const getAdditionalState = () => {
    const token = Storage.getToken()
    const mailchimpUser = JSON.parse(Storage.get('mailchimp') || '{}')
    const patreonUser = JSON.parse(Storage.get('patreon') || '{}')
    const syncMapId = Storage.get('sync-map') || ''
    return {
      ...initialState,
      isMailchimpAuthenticated: token && mailchimpUser.accessToken,
      isPatreonAuthenticated: token && patreonUser.accessToken,
      mailchimpUser,
      patreonUser,
      syncMapId
    }
  }

  const [state, dispatch] = React.useReducer(reducer, getAdditionalState())

  return (
    <AppStateContext.Provider
      value={{
        ...state,
        dispatch
      }}
    >
      {children}
    </AppStateContext.Provider>
  )
}

export default AppStateProvider
