import * as React from 'react'
import { Link } from 'react-router-dom'

import { menuRightIcon } from '../../style/icon'
import './style.scss'

interface SettingsItemProps {
  label: string
  route: string
}

const SettingItem: React.FC<SettingsItemProps> = ({ label, route }) => (
  <Link className="setting-item-container" to={route}>
    <span>{label}</span>
    <img src={menuRightIcon} />
  </Link>
)

export { SettingItem }
