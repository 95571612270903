import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { SyncMapStore } from '../../../contexts/SyncMapStore'

import useAppState from '../../../hooks/useAppState'

const GuestGuard: React.FC = ({ children }) => {
  const { isMailchimpAuthenticated, isPatreonAuthenticated } = useAppState()

  const { syncMapUuid } = React.useContext(SyncMapStore)

  if (isMailchimpAuthenticated && isPatreonAuthenticated) {
    if (syncMapUuid) {
      return <Redirect to="/settings" />
    }
    return <Redirect to="/audience" />
  }

  return <>{children}</>
}

export default GuestGuard
