import * as React from 'react'

import Container from '../Container'
import './style.scss'

const NavBar: React.FC = ({ children }) => (
  <>
    <div className="nav-bar-container">
      <Container>
        <div className="nav-bar">{children}</div>
      </Container>
    </div>
    <div className="nav-bar-placeholder" />
  </>
)

export default NavBar
