import * as React from 'react'
import { IPatreonCampaignMap } from '../interfaces/ICampaign'
import Storage from '../utils/storage'

// The shape of the data you are storing
interface ICampaignState {
  activeCampaign: IPatreonCampaignMap
}
// The shape of the data + the dispatch function
interface ICampaignContext extends ICampaignState {
  campaignDispatch: React.Dispatch<CampaignAction>
}

// The Created, empty context.  This could have a value if you wanted to
export const CampaignStore = React.createContext<ICampaignContext>({
  activeCampaign: {} as IPatreonCampaignMap,
  campaignDispatch: () => null
})

// The Provider to make useContext Available to the children
const { Provider } = CampaignStore

// Action types
type CampaignAction = { type: 'add'; campaign: IPatreonCampaignMap }

// The reducer that transforms state
const campaignReducer = (
  state: ICampaignContext,
  action: CampaignAction
): ICampaignContext => {
  switch (action.type) {
    case 'add':
      return {
        ...state,
        activeCampaign: action.campaign
      }
    default:
      throw new Error()
  }
}
const initialState = JSON.parse(Storage.get('campaigns') as string) || {}
// the provider that provides the context (built in) + adding the campaign Dispatch to the context
// This component is in the tree.  refer to AudienceSync.tsx to see this in action
const CampaignProvider: React.FC = ({ children }) => {
  const [state, campaignDispatch] = React.useReducer(
    campaignReducer,
    initialState as ICampaignContext
  )

  React.useEffect(() => {
    Storage.set('campaigns', JSON.stringify(state))
  }, [state])

  return <Provider value={{ ...state, campaignDispatch }}>{children}</Provider>
}

export default CampaignProvider
