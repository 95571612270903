import * as React from 'react'
import { Field } from 'formik'

export interface FSelectOption {
  label: string
  value: string | number
}

interface SelectProps {
  name: string
  options: FSelectOption[]
  label?: string
  placeholder?: string
  helper?: string
  handleChange?: any
}

const FSelect: React.FC<SelectProps> = ({
  name,
  options = [],
  label,
  placeholder,
  helper,
  handleChange
}) => {
  return (
    <div className="wink-form-field-container">
      {label && (
        <label className="wink-form-label" htmlFor="select">
          {label}
        </label>
      )}
      {handleChange ? (
        <Field
          as="select"
          className="wink-form-select"
          name={name}
          onChange={handleChange}
        >
          {options.map((option, key) => {
            return (
              <option key={key} value={option.value}>
                {option.label}
              </option>
            )
          })}
          {placeholder ? (
            <option value="" style={{ display: 'none' }}>
              {placeholder}
            </option>
          ) : null}
        </Field>
      ) : (
        <Field as="select" className="wink-form-select" name={name}>
          {options.map((option, key) => {
            return (
              <option key={key} value={option.value}>
                {option.label}
              </option>
            )
          })}
          {placeholder ? (
            <option value="" style={{ display: 'none' }}>
              {placeholder}
            </option>
          ) : null}
        </Field>
      )}

      {helper && <p className="wink-form-description">{helper}</p>}
    </div>
  )
}

export default FSelect
