import * as React from 'react'
import { Field } from 'formik'

interface FCheckboxProps {
  name: string
  label?: string
  helper?: string
}

const FCheckbox: React.FC<FCheckboxProps> = ({ name, label, helper }) => (
  <Field name={name}>
    {({ field }: { field: typeof Field }) => (
      <div className="wink-form-control-container">
        <label className="wink-form-control-label" id="checkbox-label">
          <input
            type="checkbox"
            className="wink-form-control wink-form-control-checkbox"
            {...field}
          />
          {label}
        </label>
        {helper && (
          <p
            aria-describedby="checkbox-label"
            className="wink-form-description"
          >
            {helper}
          </p>
        )}
      </div>
    )}
  </Field>
)

export default FCheckbox
