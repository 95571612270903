import { IMapField } from '../interfaces/IMapField'

export interface AppAction {
  type: string
  payload?: any
}

export const AUTH_INIT = '@auth/init'
export const AUTH_MAILCHIMP = '@auth/mailchimp'
export const AUTH_PATREON = '@auth/patreon'
export const MAILCHIMP_SET_DATA = '@mailchimp/set-data'
export const PATREON_SET_DATA = '@patreon/set-data'
export const SYNC_SET_ID = '@sync/set-id'
export const SYNC_SET_DATA = '@sync/set-data'
export const SYNC_SELECT_AUDIENCE = '@sync/select-audience'
export const SYNC_ADD_MAP_ITEM = '@sync/add-map-item'
export const SYNC_UPDATE_MAP_ITEM = '@sync/update-map-item'
export const SYNC_REMOVE_MAP_ITEM = '@sync/remove-map-item'

export const authInit = (): AppAction => ({
  type: AUTH_INIT
})

export const authMailchimp = (payload: any): AppAction => ({
  type: AUTH_MAILCHIMP,
  payload
})

export const authPatreon = (payload: any): AppAction => ({
  type: AUTH_PATREON,
  payload
})

export const mailchimpSetData = (payload: any): AppAction => ({
  type: MAILCHIMP_SET_DATA,
  payload
})

export const patreonSetData = (payload: any): AppAction => ({
  type: PATREON_SET_DATA,
  payload
})

export const syncSetId = (uuid: string): AppAction => ({
  type: SYNC_SET_ID,
  payload: uuid
})

export const syncSetData = (payload: any): AppAction => ({
  type: SYNC_SET_DATA,
  payload
})

export const syncSelectAudience = (audienceId: string): AppAction => ({
  type: SYNC_SELECT_AUDIENCE,
  payload: audienceId
})

export const syncAddMapItem = (name: string, item: IMapField): AppAction => ({
  type: SYNC_ADD_MAP_ITEM,
  payload: {
    name,
    item
  }
})

export const syncUpdateMapItem = (
  name: string,
  uuid: string,
  data: any
): AppAction => ({
  type: SYNC_UPDATE_MAP_ITEM,
  payload: { name, uuid, data }
})

export const syncRemoveMapItem = (name: string, uuid: string): AppAction => ({
  type: SYNC_REMOVE_MAP_ITEM,
  payload: { name, uuid }
})
