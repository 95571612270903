import * as React from 'react'
import { toast } from 'react-toastify'
import { Formik, Form } from 'formik'
import NewAudienceModal from '../../components/NewAudienceModal'
import Button from '../../components/Button'
import {
  getAudienceMergeFields,
  getAudiences,
  getAudienceSegments,
  getAudienceTags,
  getCampaigns,
  getDataFields,
  getTiers,
  revokeOauth
} from '../../utils/api'
import { IMailchimpAudienceResponse } from '../../interfaces/IAudience'
import './style.scss'
import { AudienceStore } from '../../contexts/AudienceStore'
import FSelect from '../../components/Formik/FSelect'
import { FSelectOption } from '../../components/Formik/FSelect/FSelect'
import { CampaignStore } from '../../contexts/CampaignStore'
import { SyncMapStore } from '../../contexts/SyncMapStore'
import useAuth from '../../hooks/useAuth'
import { IPatreonTier } from '../../interfaces/ICampaign'

const AudienceSync: React.FC = () => {
  const { logout } = useAuth()
  const [openCreate, setOpenCreate] = React.useState(false)
  const [audienceOptions, setAudienceOptions] = React.useState<FSelectOption[]>(
    [] as FSelectOption[]
  )
  const { audiences, mayCreate, audienceDispatch } = React.useContext(
    AudienceStore
  )
  const { audienceId, syncMapDispatch } = React.useContext(SyncMapStore)
  const { campaignDispatch } = React.useContext(CampaignStore)
  const formikRef = React.useRef({} as any)

  const onOpenWindow = (url: string) => (): void => {
    window.open(url, '_self')
  }
  // gets audience info and saves updates state
  const getAudienceDetails = (audienceId: string) => {
    Promise.all([
      getAudienceSegments(audienceId),
      getAudienceTags(audienceId),
      getAudienceMergeFields(audienceId)
    ])
      .then(([segments, tags, mergeFields]) => {
        const activeAudience = {
          id: audienceId,
          segments,
          tags,
          mergeFields
        }
        audienceDispatch({ type: 'select', audience: activeAudience })
        syncMapDispatch({
          type: 'add-audience',
          audienceId: audienceId,
          mergeFields: mergeFields
        })
      })
      .catch(async (err) => {
        try {
          await revokeOauth()
          logout()
        } catch (e) {
          toast.error(err.message)
        }
        toast.error(err.message)
      })
  }

  const getCampaignDetails = async () => {
    Promise.all([getCampaigns(), getTiers(), getDataFields()])
      .then(([campaigns, tiers, dataFields]) => {
        campaignDispatch({
          type: 'add',
          campaign: {
            id: campaigns[0].id,
            name: campaigns[0].name,
            tiers,
            dataFields
          }
        })
      })
      .catch((err) => toast.error(err.message))
  }

  // Handles Select Form Changes
  const handleChange = React.useCallback((event) => {
    const audienceId = event.target.value
    getAudienceDetails(audienceId)
    formikRef.current.handleChange(event)
  }, [])

  React.useEffect(() => {
    getAudiences()
      .then((audienceResponse: IMailchimpAudienceResponse) => {
        const { lists } = audienceResponse
        if (lists.length === 0) {
          audienceDispatch({ type: 'permission', permit: true })
        } else {
          audienceDispatch({ type: 'add', audiences: lists })
          audienceDispatch({
            type: 'permission',
            permit: audienceResponse.constraints.may_create
          })
          getAudienceDetails(lists[0].id)
        }
      })
      .catch(async (err) => {
        try {
          logout()
        } catch (e) {
          toast.error(err.message)
        }
        toast.error('Please log back in to Reauthorize')
      })

    getCampaignDetails()
  }, [])

  React.useEffect(() => {
    if (audiences) {
      const options = audiences.map(
        ({ name, id }: { name: string; id: string }) => {
          const option = {
            label: name,
            value: id
          }
          return option
        }
      )
      setAudienceOptions(options)
    }
  }, [audiences])

  return (
    <div className="audience-sync-container">
      {audiences && audiences.length > 0 ? (
        <>
          <div className="page-title">Select your Mailchimp Audience</div>
          <div>
            Select the Mailchimp Audience you&apos;d like to sync with Patreon
          </div>
          <Formik
            initialValues={{ audienceId: audienceId || '' }}
            onSubmit={() => console.log('')}
            innerRef={formikRef}
          >
            <Form>
              <div className="field-row">
                <div className="field-input-wrapper">
                  <FSelect
                    handleChange={handleChange}
                    label="Your Mailchimp Audiences"
                    name="audienceId"
                    options={audienceOptions}
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </>
      ) : null}
      {mayCreate ? (
        <Button
          variant="secondary"
          label="Create an Audience"
          onClick={() => setOpenCreate(true)}
        />
      ) : (
        <p
          className={'wink-text'}
          style={{
            cursor: 'pointer',
            color: '#007c89',
            textDecoration: 'underline'
          }}
          onClick={onOpenWindow(
            'https://mailchimp.com/pricing/?gclid=Cj0KCQiAmL-ABhDFARIsAKywVacB_rhxeOQ6_eq9kxPqRkao5_d7vl0X11N1fBt43ASCZHBL_kOoclUaAoOiEALw_wcB&gclsrc=aw.ds'
          )}
        >
          Upgrade your plan to create a new audience
        </p>
      )}

      <NewAudienceModal
        open={openCreate}
        onClose={() => {
          setOpenCreate(false)
          getAudiences()
        }}
      />
    </div>
  )
}

export { AudienceSync }
