import * as React from 'react'
import { useLocation } from 'react-router-dom'

import NavBar from '../../components/NavBar'
import Container from '../../components/Container'
import Footer from '../../components/Footer'
import Breadcrumb from '../../components/Breadcrumb'
import Button from '../../components/Button'
import useNavigation from '../../hooks/useNavigation'
import { STEPS } from '../../utils/constants'
import './style.scss'

const MainLayout: React.FC = ({ children }) => {
  const location = useLocation()
  const { handleNext, isNextAvailable } = useNavigation()

  const current = location.pathname

  const onNext = () => {
    handleNext(current)
  }

  return (
    <div className="main-layout-container">
      <NavBar>
        <div className="nav-bar-title-wrapper">
          <div className="nav-bar-title">Mailchimp Patreon Integration</div>
          <Breadcrumb
            items={Object.values(STEPS).map((item) => ({
              ...item,
              isActive: item.route === current
            }))}
          />
        </div>
        <div className="action-button-wrapper">
          <Button
            variant="primary"
            label={current === '/merge-field' ? 'Finish' : 'Next'}
            onClick={onNext}
            disabled={!isNextAvailable(current)}
          />
        </div>
      </NavBar>
      <Container>
        <div className="content">{children}</div>
      </Container>
      <Footer>
        <div className="footer-wrapper">
          <Button
            variant="primary"
            label={current === '/merge-field' ? 'Finish' : 'Next'}
            onClick={onNext}
          />
        </div>
      </Footer>
    </div>
  )
}

export default MainLayout
