import * as React from 'react'

import Container from '../Container'
import './style.scss'

const Footer: React.FC = ({ children }) => (
  <div className="footer-container">
    <Container>
      <div className="footer">{children}</div>
    </Container>
  </div>
)

export default Footer
