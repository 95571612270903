import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import useAuth from '../../hooks/useAuth'
import useQuery from '../../hooks/useQuery'
import { authorizeMailchimp } from '../../utils/api'
import './style.scss'

const MailchimpAuth: React.FC = () => {
  const history = useHistory()
  const { setAuthMailchimp } = useAuth()
  const { getParam } = useQuery()

  const code = getParam('code')

  React.useEffect(() => {
    if (!code) {
      return
    }
    authorizeMailchimp(code)
      .then((res) => {
        setAuthMailchimp(res.token, res.mailchimpUser)
        toast.success('Mailchimp authentication success.')
      })
      .catch((err) => {
        toast.error(err.message)
      })
      .finally(() => history.replace('/auth'))
  }, [code])

  return <div>Authenticating with Mailchimp...</div>
}

export { MailchimpAuth }
